export default [
  // Put all routes here
  'home',
  'about',
  'single',
  'templateAccueil',
  'templateContact',
  'templateLogin',
  'templateFreeSectionPage',
  'templateEspaceDebiteur',
  'templateEspaceCarriereAccueil',
  'templateFreeSectionCarrierePage',
  'templateExperienceIqera',
  'templateExperiencesEtConseils',
  'templateActualites',
  'templateCarriereActualites',
  'templateInvestorPublications',
  'templateInvestorParams',
  'templatePayment',
]
